<template>
  <prime-dropdown
    v-model="model"
    :options="options"
    :option-label="optionLabel"
    :option-value="optionValue"
    :filter="filter"
    :class="[
      $attrs.class,
      { 'de-overlay-opened': isOpened, 'is-focused': isFocused, 'is-disabled': disabled },
    ]"
    :placeholder="placeholder"
    :filter-placeholder="filterPlaceholder"
    :show-clear="showClear"
    :auto-filter-focus="autoFilterFocus"
    :reset-filter-on-clear="resetFilterOnClear"
    :reset-filter-on-hide="resetFilterOnHide"
    :disabled="disabled"
    :popup-alignment="popupAlignment"
    :append-to="appendTo"
    :pt="dropdownClasses"
    @change="onChange"
    @before-show="onShow"
    @before-hide="onHide"
    @filter="onFilter"
    @focus="onFocus"
    @blur="onBlur"
  >
    <!--    pass through all slots-->
    <template v-for="(_, slot) in $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>

    <template #filtericon>
      <de-svg-icon name="search" class="tw-w-3.5 tw-h-3.5" :class="dropdownClasses.filterIcon" />
    </template>

    <template #dropdownicon>
      <div class="de-dropdown-trigger">
        <slot name="dropdownIcon" :props="{ opened: isOpened }">
          <button class="tw-w-full tw-h-full tw-flex tw-items-center">
            <de-svg-icon
              :name="dropdownIcon"
              class="tw-transform"
              :class="[{ 'tw-rotate-180': isOpened }, dropdownIconClass]"
            />
          </button>
        </slot>
      </div>
    </template>

    <template #clearicon="{ clearCallback }">
      <button @click.stop="clearCallback">
        <de-svg-icon name="close-circle" class="tw-w-3 tw-h-3" />
      </button>
    </template>

    <template #emptyfilter>
      <div class="tw-text-300 tw-leading-400 tw-text-primary-300 tw-text-center tw-py-2.5">
        {{ t('form.noResultsFor', { text: filterValue }) }}
      </div>
    </template>
  </prime-dropdown>
</template>

<script setup lang="ts">
import type { DropdownChangeEvent, DropdownFilterEvent } from 'primevue/dropdown';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { computed } from '#imports';
import DeSvgIcon from '~/shared/components/lib/svg-icon/DeSvgIcon.vue';

const props = withDefaults(
  defineProps<{
    options: any[];
    optionLabel?: string | (() => string);
    optionValue?: string | (() => string);
    type?: 'default' | 'chips' | 'form';
    filter?: boolean;
    appendTo?: HTMLElement | 'body' | 'self';
    popupAlignment?: 'left' | 'right' | 'center';
    listItemClass?: string;
    panelClass?: string;
    dropdownIconClass?: string;
    placeholder?: string;
    filterPlaceholder?: string;
    showClear?: boolean;
    autoFilterFocus?: boolean;
    resetFilterOnClear?: boolean;
    resetFilterOnHide?: boolean;
    label?: string;
    inputId?: string;
    id?: string;
    disabled?: boolean;
  }>(),
  {
    popupAlignment: 'left',
    type: 'default',
  },
);

const emit = defineEmits<{
  change: [event: DropdownChangeEvent];
}>();

const { t } = useI18n();
const model = defineModel<any>({ required: true });

const dropdownClasses = computed(() => {
  const classes = {
    root: ['de-dropdown', `de-dropdown-${props.type}`],
    input: ['de-dropdown-input', { 'de-dropdown-input-placeholder': !model.value }],
    panel: [
      'de-dropdown-panel',
      { 'tw-right-0 !tw-left-auto': props.popupAlignment === 'right' },
      { '!tw-left-1/2 tw-transform tw--translate-x-1/2': props.popupAlignment === 'center' },
      { 'de-dropdown-panel-filter': props.filter },
    ],
    wrapper: ['de-dropdown-panel-wrapper'],
    list: ['de-dropdown-list'],
    item: ['de-dropdown-list-item'],
    filterContainer: ['de-dropdown-filter-container'],
    filterInput: ['de-dropdown-filter-input'],
    filterIcon: ['de-dropdown-filter-icon'],
    transition: {
      enterFromClass: 'de-connected-overlay-enter-from',
      enterActiveClass: 'de-connected-overlay-enter-active',
      leaveActiveClass: 'de-connected-overlay-leave-active',
      leaveToClass: 'de-connected-overlay-leave-to',
    },
  };

  if (props.listItemClass) {
    classes.item.push(props.listItemClass);
  }

  if (props.panelClass) {
    classes.panel.push(props.panelClass);
  }

  return classes;
});
const dropdownIcon = computed(() => {
  return props.type === 'form' ? 'chevron-down' : 'chevron-down-filled';
});

const isOpened = ref(false);
const isFocused = ref(false);

function onShow() {
  isOpened.value = true;
}

function onHide() {
  isOpened.value = false;
}

function onFocus() {
  isFocused.value = true;
}

function onBlur() {
  isFocused.value = false;
}

function onChange(event: DropdownChangeEvent) {
  emit('change', event);
}

const filterValue = ref(null);
function onFilter(event: DropdownFilterEvent) {
  filterValue.value = event.value;
}
</script>
